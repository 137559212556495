<!--  -->
<template>
  <div class=""><router-view></router-view></div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
}
</script>
<style lang='scss' scoped>
</style>